import { getAccessToken } from "request";
import { StatusProp } from "../interfaces/purchaseorder.interface";
import axios from "axios";

export const formatCurrency = (amount: any) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    // style: 'currency',
    // currency: 'NGN',
    style: "decimal",
    minimumFractionDigits: 2,
  });

  return amount ? formatter.format(amount) : "0.00";
};

export const formatWithCommas = (value: any) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "decimal",
  });

  return value ? formatter.format(value) : 0;
};

export const formatDateTime = (datetime: string) => {
  const dateString = datetime;
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  if (datetime) return formattedDate.replace(/,/g, "");
  else return "N/A";
};

export const formatDateToWords = (dateString: string): string => {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
    // hour: '2-digit',
    // minute:'2-digit',
    // hour12: false
  });
};

export const getDateDifference = (numberOfDays: number): string => {
  // Get the current date
  const currentDate = new Date();

  // Subtract 7 days from the current date
  const pastDate = new Date(currentDate);
  pastDate.setDate(currentDate.getDate() - numberOfDays);

  // Format the date
  const formattedDate = pastDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return formattedDate;
};

export const getDateDifferenceLeadTime = (DateTime: string) => {
  const givenDate: any = new Date(DateTime);
  const currentDate: any = new Date();

  // Calculate the difference in milliseconds
  const differenceMs = currentDate - givenDate;

  // Calculate days
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  // Calculate remaining hours, minutes, and seconds
  const hours = Math.floor(
    (differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((differenceMs % (1000 * 60)) / 1000);

  return `${days} Day(s) ${hours} Hr(s) ${minutes} Min(s)`;
};

export const GUID = (length: number) => {
  // Characters to use for generating the GUID
  // const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const characters = "0123456789";

  let guid = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    guid += characters.charAt(randomIndex);
  }

  return guid;
};

export var currentDatetime = new Date(
  new Date().getTime() + 1 * 60 * 60 * 1000
).toISOString();

export const POItemStatus: () => StatusProp[] = () => {
  const status: StatusProp[] = [
    { value: "", name: "All Status" },
    { value: "1", name: "Unfulfilled" },
    { value: "2", name: "Partially Fulfilled" },
    { value: "3", name: "Fulfilled" },
  ];

  return status;
};

export const getPurchaseOrderItemStatus = (value: string) => {
  switch (value) {
    case "UNFULFILLED":
      return { value: "Unfulfilled", color: "status red" };

    case "PARTIAL":
      return { value: "Partially Fulfilled", color: "status yellow" };

    case "FULFILLED":
      return { value: "Fulfilled", color: "status green" };

    // case 4:
    //     return {value: "Completed", color: "status green"}
  }
};

export const truncateText = (text: any, maxLength: any) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

export const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export const POItemSupplyStatus: () => StatusProp[] = () => {
  const status: StatusProp[] = [
    { value: "", name: "All Status" },
    { value: "UNASSIGNED", name: "Unassigned" },
    // { value: "READY_TO_ASSIGN", name: "Ready to Assign" },
    { value: "ASSIGNED", name: "Assigned" },
    { value: "PICKED", name: "Picked Up" },
    { value: "SHIPPED", name: "Shipped" },
    { value: "ARRIVED_IN_COUNTRY", name: "Arrived in Country" },
    { value: "CUSTOM_CLEARANCE", name: "Custom Clearance" },
    { value: "CLEARED_AT_CUSTOM", name: "Cleared at Custom" },
    { value: "DELIVERED_AT_BASE", name: "Delivered at Base" },
  ];

  return status;
};

export const getPOItemSupplyStatusIndex = (value: string) => {
  switch (value) {
    case "UNASSIGNED":
      return 1;

    // case "READY_TO_ASSIGN":
    //   return 2;

    case "ASSIGNED":
      return 2;

    case "PICKED":
      return 3;

    case "SHIPPED":
      return 4;

    case "ARRIVED_IN_COUNTRY":
      return 5;

    case "CUSTOM_CLEARANCE":
      return 6;

    case "CLEARED_AT_CUSTOM":
      return 7;

    case "DELIVERED_AT_BASE":
      return 8;

    default:
      return 1;
  }
};

export const ClearingProcessStatus: () => StatusProp[] = () => {
  const status: StatusProp[] = [
    { value: 1, name: "Freight Forwarder Shipping Documents Uploaded" },
    { value: 2, name: "Vessel Arrival Confirmation" },
    { value: 3, name: "Awaiting Custom Duty Payment" },
    { value: 4, name: "Custom Physical Examination Ongoing" },
    { value: 5, name: "Readiness To Transfer To Total Energies Yard" },
  ];

  return status;
};

export const getFreightForwarderMRDStatus = (value: number) => {
  switch (value) {
    case 2:
      return { value: "Pending", color: "status red" };

    case 3:
      return { value: "Open", color: "status blue" };

    case 4:
      return { value: "Closed", color: "status green" };
  }
};

export const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case "USD":
      return "$";

    case "EUR":
      return "€";

    case "GBP":
      return "£";

    default:
      return "";
  }
};

export const getYearToCurrentYear = (startYear: number): number[] => {
  const currentYear = new Date().getFullYear();
  const yearsArray: number[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
};

export const getStatusAndColorForMRDUploads = (status: boolean) => {
  switch (status) {
    case null:
      return {
        color: "yellow",
        statusText: "Awaiting Approval",
        icon: "pending",
      };

    case true:
      return {
        color: "green",
        statusText: "Approved",
        icon: "check_circle",
      };

    case false:
      return {
        color: "red",
        statusText: "Rejected",
        icon: "cancel",
      };

    default:
      return {
        color: "yellow",
        statusText: "Awaiting Approval",
        icon: "pending",
      };
  }
};

export const getMaterialReadinessDocumentChatTitle = (
  sender: string,
  companyName: string,
  senderRole: string
) => {
  switch (sender) {
    case "TransitTeam":
      return "Transit Team";
    case "ExpeditingTeam":
      return `Expediting Team (${senderRole})`;
    default: {
      if (senderRole.includes("Freight Forwarder"))
        return `Freight Forwarder - ${companyName}`;
      if (senderRole.includes("Supplier")) return `Supplier (${companyName})`;
    }
  }
};

export const destinations = [
  "NNPC ELF ONNE-BASE ONNE NIGERIA NIGERIA",
  "Ladol Free Zone, Apapa Port, Lagos",
];

export const entityDepartments = [
  "Drilling",
  "Field Operations",
  "Medicals"
];

/**
 * Safely encodes a URI component to prevent injection attacks.
 *
 * @param param - The string parameter to encode.
 * @returns The encoded URI component string.
 */
export function encodeParameter(param: string): string {
  return encodeURIComponent(param);
}

const encodedAccessToken = encodeParameter(getAccessToken());

export const handleDownloadForPOItemSupplyAttachment = async (
  documentBlobStorageName: string,
  documentName: string,
  purchaseOrderItemSuppliesId: string,
) => {
  // id = purchaseOrderItemSupplyId
  try {
    const encodedDocumentName = encodeParameter(documentName);
    const encodedPurchaseOrderItemSuppliesId = encodeParameter(purchaseOrderItemSuppliesId)    
    const encodedDocumentBlobStorageName = encodeParameter(documentBlobStorageName);
    
    // Fetch the file from the API
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const requestUrl = `PurchaseOrderItem/DownloadPurchaseOrderItemSupplyAttachment/${encodedPurchaseOrderItemSuppliesId}/${encodedDocumentName}`;
    const response = await fetch(baseUrl + requestUrl, {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + encodedAccessToken
      },
    });
    const blob = await response.blob();

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Create an anchor element
    const a = document.createElement("a");
    a.href = blobUrl;
    const currentDatetime = new Date(
      new Date().getTime() + 1 * 60 * 60 * 1000
    ).toISOString();
    const fileFormat = encodedDocumentBlobStorageName.split(".")[1];

    a.download = `${documentName}_${currentDatetime.slice(
      0,
      -5
    )}.${fileFormat}`; // Set the desired file name

    // Trigger the click event to initiate the download
    a.click();

    // Cleanup: Revoke the Blob URL
    URL.revokeObjectURL(blobUrl);
    return false
    
  } catch (error) {
    console.log("Error downloading file:", error); return false;
  }
};

export const handlePreviewForPoItemSupply = async (documentName: string, purchaseOrderItemSuppliesId: string) => {
  try {
    const encodedDocumentName = encodeParameter(documentName);
    const encodedPurchaseOrderItemSuppliesId = encodeParameter(purchaseOrderItemSuppliesId);
  
    // Base URL and API endpoint
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const requestUrl = `${baseUrl}/PurchaseOrderItem/PreviewPurchaseOrderItemSupplyAttachment/${encodedDocumentName}`;
  
    // Fetch the file from the API using axios
    const response: any = await axios.get(requestUrl, {
      headers: {
        'Authorization': 'Bearer ' + encodedAccessToken
      },
      params: {
        purchaseOrderItemSupplyId: encodedPurchaseOrderItemSuppliesId // Append query parameter
      },
      responseType: 'blob' // To handle binary data such as a PDF
    });
  
    // Handle the response (e.g., preview PDF)
    handlePreviewPDF(response.data);
 
    return false;
  } catch (error) {
    console.error('Error downloading file:', error);
    return false;
  }
  // try {
  //     const encodedDocumentName = encodeParameter(documentName);
  //     const encodedPurchaseOrderItemSuppliesId = encodeParameter(purchaseOrderItemSuppliesId)      
      
  //     // Fetch the file from the API
  //     const baseUrl = process.env.REACT_APP_SERVER_URL;
  //     const requestUrl = new URL(`PurchaseOrderItem/PreviewPurchaseOrderItemSupplyAttachment/${encodedDocumentName}`, baseUrl);

  //     // Create query parameters using URLSearchParams
  //     const params = new URLSearchParams({
  //         purchaseOrderItemSupplyId: encodedPurchaseOrderItemSuppliesId
  //     });
  //     requestUrl.search = params.toString(); // Append the query string to the URL

  //     const response = await fetch(requestUrl.toString(), {
  //       method: 'GET',
  //       headers: {
  //           'Authorization': 'Bearer ' + encodedAccessToken
  //       },
  //     });
  //     handlePreviewPDF(response)
  //     return false
      
  // } catch (error) {
  //   console.log('Error downloading file:', error); return false;
  // }
};

export const handleDownloadForPackageAttachment = async (
  packageId: string, 
  documentBlobStorageName: string, 
  documentName: string
) => {
  // id = purchaseOrderItemSupplyId
  try {      
      const encodedDocumentName = encodeParameter(documentName);
      const encodedPackageId = encodeParameter(packageId);
      const encodedDocumentBlobStorageName = encodeParameter(documentBlobStorageName);

      // Fetch the file from the API
      const baseUrl = process.env.REACT_APP_SERVER_URL
      const requestUrl = `Package/DownloadPackageAttachment/${encodedPackageId}/${encodedDocumentName}`
      const response = await fetch(baseUrl + requestUrl, {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + encodedAccessToken
          },
      });
      const blob = await response.blob();
  
      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);
  
      // Create an anchor element
      const a = document.createElement('a');
      a.href = blobUrl;
      const currentDatetime = new Date(new Date().getTime() + 1 * 60 * 60 * 1000).toISOString()
      const fileFormat = encodedDocumentBlobStorageName.split(".")[1]
      
      a.download = `${documentName}_${currentDatetime.slice(0, -5)}.${fileFormat}`; // Set the desired file name
      
      // Trigger the click event to initiate the download
      a.click();
  
      // Cleanup: Revoke the Blob URL
      URL.revokeObjectURL(blobUrl);
      return false

  } catch (error) {
    console.log('Error downloading file:', error); return false;
  }
};


export const handlePreviewForPackageAttachment = async (packageId: string, documentName: string) => {
  try {
    const encodedDocumentName = encodeParameter(documentName);
    const encodedPackageId = encodeParameter(packageId);
    
    // Base URL
    const baseUrl = process.env.REACT_APP_SERVER_URL;
  
    // API endpoint
    const requestUrl = `${baseUrl}/Package/PreviewPackageAttachment/${encodedDocumentName}`;
  
    // Fetch the file from the API using axios
    const response = await axios.get(requestUrl, {
      headers: {
        'Authorization': 'Bearer ' + encodedAccessToken
      },
      params: {
        packageId: encodedPackageId // Append query parameter
      },
      responseType: 'blob' // If the API response is a file (PDF, image, etc.)
    });
  
    // Handle the response (e.g., preview PDF)
    handlePreviewPDF(response.data);
    
    return false;
  } catch (error) {
    console.error('Error downloading file:', error);
    return false;
  }
  // try {
      
  //     const encodedDocumentName = encodeParameter(documentName);
  //     const encodedPackageId = encodeParameter(packageId);
  //     // Fetch the file from the API
  //     // const baseUrl = process.env.REACT_APP_SERVER_URL
  //     // const requestUrl = `Package/PreviewPackageAttachment/${encodedDocumentName}?packageId=${encodedPackageId}`
  //     // const response = await fetch(baseUrl + requestUrl, {
  //     //   method: 'GET',
  //     //   headers: {
  //     //       'Authorization': 'Bearer ' + encodedAccessToken
  //     //   },
  //     // });
  //     const baseUrl = process.env.REACT_APP_SERVER_URL;
  //     const requestUrl = new URL(`Package/PreviewPackageAttachment/${encodedDocumentName}`, baseUrl);

  //     // Create query parameters using URLSearchParams
  //     const params = new URLSearchParams({
  //         packageId: encodedPackageId
  //     });
  //     requestUrl.search = params.toString(); // Append the query string to the URL

  //     const response = await fetch(requestUrl.toString(), {
  //       method: 'GET',
  //       headers: {
  //           'Authorization': 'Bearer ' + encodedAccessToken
  //       },
  //     });
  //     handlePreviewPDF(response)
  //     return false
  // } catch (error) {
  //   console.log('Error downloading file:', error); return false;
  // }
};

export const getDocumentBlobStorageName = (shipment: any, documentName: string) => {
  return shipment?.shippingDocumentAttachments?.reduce((prev: any, curr: any) => {
  return curr.documentName === documentName ? curr : prev;
  }, null)?.documentBlobStorageName;
};

export const handleDownloadForShippingDocuments = async (
  shipment: any, 
  documentName: string, 
  title: string) => {
  try {
      const encodedDocumentName = encodeParameter(documentName);
      const encodedShippingDocumentId = encodeParameter(shipment.id)
      

      // Fetch the file from the API
      const baseUrl = process.env.REACT_APP_SERVER_URL
      const requestUrl = `ShippingDocument/DownloadShippingDocument/${encodedShippingDocumentId}/${encodedDocumentName}`
      const response = await fetch(baseUrl + requestUrl, {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + encodedAccessToken
          },
      });
      const blob = await response.blob();
  
      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);
  
      // Create an anchor element
      const a = document.createElement('a');
      a.href = blobUrl;
      const currentDatetime = new Date(new Date().getTime() + 1 * 60 * 60 * 1000).toISOString()            
      const fileFormat = getDocumentBlobStorageName(shipment, documentName).split(".")[1]
      
      a.download = `${title}${currentDatetime.slice(0, -5)}.${fileFormat}`; // Set the desired file name
      
      // Trigger the click event to initiate the download
      a.click();
  
      // Cleanup: Revoke the Blob URL
      URL.revokeObjectURL(blobUrl);
      return false

  } catch (error) {
    console.log('Error downloading file:', error); return false;
  }
};

export const handlePreviewForShippingDocuments = async (shipment: any, documentName: string) => {
  try {
    const encodedDocumentName = encodeParameter(documentName);
    const encodedShippingDocumentId = encodeParameter(shipment.id);
  
    // Base URL and API endpoint
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const requestUrl = `${baseUrl}/ShippingDocument/PreviewShippingDocument/${encodedDocumentName}`;
  
    // Fetch the file from the API using axios
    const response = await axios.get(requestUrl, {
      headers: {
        'Authorization': 'Bearer ' + encodedAccessToken
      },
      params: {
        shippingDocumentId: encodedShippingDocumentId // Append query parameter
      },
      responseType: 'blob' // If the response is a file (PDF or other binary data)
    });
  
    // Handle the response (e.g., preview PDF)
    handlePreviewPDF(response.data);
  
    return false;
  } catch (error) {
    console.error('Error downloading file:', error);
    return false;
  }
  // try {
  //     const encodedDocumentName = encodeParameter(documentName);
  //     const encodedShippingDocumentId = encodeParameter(shipment.id)      

  //     // Fetch the file from the API
  //     // const baseUrl = process.env.REACT_APP_SERVER_URL
  //     // const requestUrl = `ShippingDocument/PreviewShippingDocument/${encodedDocumentName}?shippingDocumentId=${encodedShippingDocumentId}`
  //     // const response = await fetch(baseUrl + requestUrl, {
  //     //   method: 'GET',
  //     //   headers: {
  //     //       'Authorization': 'Bearer ' + encodedAccessToken
  //     //   },
  //     // });

  //     const baseUrl = process.env.REACT_APP_SERVER_URL;
  //     const requestUrl = new URL(`ShippingDocument/PreviewShippingDocument/${encodedDocumentName}`, baseUrl);

  //     // Create query parameters using URLSearchParams
  //     const params = new URLSearchParams({
  //       shippingDocumentId: encodedShippingDocumentId
  //     });
  //     requestUrl.search = params.toString(); // Append the query string to the URL

  //     const response = await fetch(requestUrl.toString(), {
  //       method: 'GET',
  //       headers: {
  //           'Authorization': 'Bearer ' + encodedAccessToken
  //       },
  //     });
  //     handlePreviewPDF(response)
  //     return false
  // } catch (error) {
  //   console.log('Error downloading file:', error); return false;
  // }
};

const handlePreviewPDF = async (response: any) => {
  try {
    const base64String: any = await response.text()            
    const dataObj = JSON.parse(base64String);
    
    const base64Data = `data:application/pdf;base64, ${dataObj.data}`
    const pdfWindow = window.open("")
    if(pdfWindow) {
        pdfWindow.document.write(
            "<iframe width='100%' height='100%' src='" + base64Data + "'></iframe>"
        )
        pdfWindow.document.close(); // Needed for IE11
    }
  }catch (error) {
    console.log("Error downloading file:", error);
  }
}

// const handlePreviewPDF = async (response: any) => {
//   try {
//     const base64String: any = await response.text();
//     const dataObj = JSON.parse(base64String);

//     // Validate that the received data is in the expected format
//     if (!dataObj || typeof dataObj.data !== 'string') {
//       throw new Error("Unexpected data format");
//     }

//     // Decode the base64 content into a Blob to avoid direct base64 injection
//     const binaryString = window.atob(dataObj.data); // Convert base64 to binary
//     const len = binaryString.length;
//     const bytes = new Uint8Array(len);
    
//     for (let i = 0; i < len; i++) {
//       bytes[i] = binaryString.charCodeAt(i);
//     }

//     const blob = new Blob([bytes], { type: 'application/pdf' });

//     // Create a safer URL using URL.createObjectURL
//     const objectUrl = URL.createObjectURL(blob);

//     // Open the PDF in a new window
//     const pdfWindow = window.open('');
//     if (pdfWindow) {
//       pdfWindow.document.write(`
//         <iframe width="100%" height="100%" src="${objectUrl}" frameborder="0" allowfullscreen></iframe>
//       `);
//       pdfWindow.document.close(); // Needed for IE11
//     }

//     // Release the object URL after the PDF has loaded
//     pdfWindow?.addEventListener('load', () => {
//       URL.revokeObjectURL(objectUrl); // Clean up to avoid memory leaks
//     });
//   } catch (error) {
//     console.error("Error displaying PDF:", error);
//   }
// };

export const handleDownloadForMOTChangeRequest = async (
  modeOfTransportation: any, 
  title: string) => {
  try {
      const encodedModeOfTransportationAttachmentId = encodeParameter(modeOfTransportation.id)      

      // Fetch the file from the API
      const baseUrl = process.env.REACT_APP_SERVER_URL
      const requestUrl = `ModeOfTransportation/DownloadModeOfTransportationAttachment/${encodedModeOfTransportationAttachmentId}`
      const response = await fetch(baseUrl + requestUrl, {
          method: 'GET',
          headers: {
              'Authorization': 'Bearer ' + encodedAccessToken
          },
      });
      const blob = await response.blob();
  
      // Create a Blob URL
      const blobUrl = URL.createObjectURL(blob);
  
      // Create an anchor element
      const a = document.createElement('a');
      a.href = blobUrl;
      const currentDatetime = new Date(new Date().getTime() + 1 * 60 * 60 * 1000).toISOString()            
      const fileFormat = modeOfTransportation.documentBlobStorageName.split(".")[1]
      
      a.download = `${title}_${currentDatetime.slice(0, -5)}.${fileFormat}`; // Set the desired file name
      
      // Trigger the click event to initiate the download
      a.click();
  
      // Cleanup: Revoke the Blob URL
      URL.revokeObjectURL(blobUrl);
      return false

  } catch (error) {
    console.log('Error downloading file:', error); return false;
  }
};
