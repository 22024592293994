import { useState, } from 'react';
import html3pdf from 'html3pdf';
import { currentDatetime, customStyles, formatDateTime} from '../../helpers';
import loading from "../../assets/images/loading.gif"
import Modal from "react-modal"
// import { makeGetRequest, makePatchRequest } from "../../request";
// import { useParams } from "react-router-dom";
import { ToastContainer,  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useSelector } from 'react-redux';
// import { useNavigate } from "react-router-dom";

const PdfGeneratorPackingList = (props) => {
  const { data, otherData } = props
  const packageInformation = data.packages[0]
  const [isLoading,] = useState(false)
  
  const generatePdf = () => {
    const pdfContent = document.getElementById('pdf-content');

    html3pdf(pdfContent, {
      margin: 10,
      filename: `${data?.packingListNumber}-${currentDatetime.substring(0, 10)}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html3canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    });
  };

  return (
    <div style={{padding: "12px 0", minHeight: "calc(100vh - 160px)"}}>
      {/* Button to generate PDF */}
      <div style={{display: "flex",justifyContent: "end"}}>
        <div className='actions blue' style={{width: "fit-content"}} onClick={generatePdf}>
          <span className="material-symbols-rounded">download</span>
          <span>Download PDF</span>
        </div>
      </div>
      <hr style={{border: "1px solid #e9e9e9", marginTop: "12px"}} />

      {/*  div with content for PDF generation */}
      <div id="pdf-content" className='pdf-content' style={{padding: "0 24px"}}>
        <h5 className='text-center'>PACKING LIST</h5>
        <div className='d-flex mt-2' style={{gap: "24px"}}>
          <div>
            <p>ISSUANCE DATE: <span className='fw-600'>{formatDateTime(data?.createdDate)}</span></p>
            <p className='mt-1'>PACKING LIST NUMBER: <span className='fw-600'>{data?.packingListNumber}</span></p>

            <p style={{marginTop: "18px"}}>SUPPLIER: <span className='fw-600'>{data?.supplier.toUpperCase()}</span></p>
            <p>PICKUP ADDRESS: <span className='fw-600'>{otherData?.pickUpAddress}</span></p>

            {/* {packingList?.modeOfShipping === "Air" && 
              <>
                <p className='mt-1'>Form M: <span className='fw-600'>{packingList?.formM}</span></p>
                <p className='mt-1'>BA Number: <span className='fw-600'>{packingList?.baNumber}</span></p>
              </>} */}
            <p>PURCHASE ORDER NUMBER: <span className='fw-600 text-blue'>{packageInformation ? packageInformation.purchaseOrderItemSupplies[0]?.purchaseOrderItem.purchaseOrderNumber : ""}</span></p>
            
          </div>

          <div>
            <div className='card'>
              <span className='no-textwrap'>Sold To:</span>
              <p className='fw-600'>{otherData?.soldTo}</p>
            </div>
            <div className='card mt-1'>
              <span className='no-textwrap'>Ship To:</span>
              <p className='fw-600'>{otherData?.shipTo}</p>
            </div>
          </div>
        </div>
        
        <table className='custom-table' style={{marginTop: "8px"}}>
          <thead>
            <tr>
              {/* <th className='text-center'>PACKAGES</th> */}
              <th className='text-center'>LENGTH</th>
              <th className='text-center'>WIDTH</th>
              <th className='text-center'>HEIGHT</th>
              <th className='text-center'>CUBIC METER</th>
              <th className='text-center'>GROSS WEIGHT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td className='text-center'>Package {packageInformation.packageNumber}</td> */}
              <td className='text-center'>{packageInformation.length}</td>
              <td className='text-center'>{packageInformation.width}</td>
              <td className='text-center'>{packageInformation.height}</td>
              <td className='text-center'>{packageInformation.cubicMeter}</td>
              <td className='text-center'>{packageInformation.grossWeight}</td>
            </tr>
          </tbody>
          </table>
          <table className='custom-table mt-2'>
          <thead>
            <tr>
              <th className='text-center'>SUPPLIER MATERIAL</th>
              <th className='text-center'>PO ITEM NUMBER</th>
              <th>MATERIAL DESCRIPTION</th>             
              <th className='text-center'>HS CODES</th>
              <th className='text-center'>COUNTRY OF ORIGIN</th>
              <th className='text-center'>QUANTITY</th>
              {/* <th className='text-center'>SUPPLIED QTY</th> */}
            </tr>
          </thead>
          <tbody>
            {packageInformation?.purchaseOrderItemSupplies.sort((a, b) => a.purchaseOrderItemNumber - b.purchaseOrderItemNumber)?.map((row, index) => (
              <tr key={index}>
                <td className='text-center fw-600'>{row.purchaseOrderItem.materialNumber}</td>
                <td className='text-center'>{row.purchaseOrderItem.purchaseOrderItemNumber}</td>
                <td>{row.purchaseOrderItem.materialDescription}</td>
                <td className='text-center'>{row.harmonisedSystemCode}</td>
                <td className='text-center'>{row.countryOfOrigin}</td>
                <td className='text-center'>{row.quantity}</td>
                {/* <td className='text-center'>{row.suppliedQty}</td> */}
              </tr>
            ))}

            <tr>
              <td colSpan={5} className='fw-600' style={{textAlign: "right", marginRight: "12px"}}>Total PCS</td>
              <td className='text-center fw-600 yellow'>{packageInformation?.purchaseOrderItemSupplies.reduce((acc, item) => acc + parseFloat(item.quantity), 0)}</td>
            </tr>            

            <tr>
              <td className='no-textwrap'>Country of Supply</td>
              <td className='fw-600' colSpan={6}>{data?.countryOfSupply}</td>
            </tr>
            <tr>
              <td>Mode of Shipping</td>
              <td className='fw-600' colSpan={6}>{data?.modeOfShipping}</td>
            </tr>
            <tr>
              <td>Destination</td>
              <td className='fw-600' colSpan={6}>{data?.destination}</td>
            </tr>            
          </tbody>
        </table>
        <Modal isOpen={isLoading} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
            <div className="loader">
                <img src={loading} alt="loading" />
                <p>Loading data...</p>
            </div>
        </Modal> 
        <ToastContainer />
      </div>
    </div>
  );
};

export default PdfGeneratorPackingList;
