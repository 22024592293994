import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import { ReactProps } from "../interfaces/index.interface"
import { useDispatch, useSelector } from "react-redux";
import { makeGetRequest, refreshToken } from "../request";
import { setTepngUser } from "../store/tepngUser";
import { setAccessToken } from "../store/accessToken";
import { useEffect } from 'react';
import { setRoles } from '../store/roles';
import { setUnreadCount } from "store/unreadCount";
import { setPermissions } from "store/permissions";

const Layout  = ({children, title}: ReactProps) => {
    const dispatch = useDispatch()
    const accessToken:any = useSelector((state: any) => state.accessToken.value);
    const user:any = useSelector((state: any) => state.tepngUser.value);
    // const unreadCount:number = useSelector((state: any) => state.unreadCount.value);
    const role = window.location.pathname.split('/')[1]
    const getUnreadNotificationsCount = () => {
        let apiCallParameters = {
            endpoint: "",
            receiver: ""
        }
        if(role === "supplier") apiCallParameters.endpoint = "getUnreadMaterialReadinessDocumentNotifications"
        else if(["transitofficer", "freightforwarder", "localclearingagent"].includes(role)) apiCallParameters.endpoint = "getUnreadShippingDocumentNotifications"

        if(role === "transitofficer") apiCallParameters.receiver = "TransitOfficer"
        else apiCallParameters.receiver = user?.id

        var request: Record<string, any> = {
            what: apiCallParameters.endpoint,
            params: {
                Receiver: apiCallParameters.receiver,
            }
        };
        
        if(Object.values(apiCallParameters).every(value => value !== "")) {
            makeGetRequest(request)
            .then((response: any) => {
                const res = response.data.data
                // setUnreadCount(3)
                dispatch(setUnreadCount(res.length))
            })
        }
    }

    useEffect(() => {
        getUnreadNotificationsCount();
        //eslint-disable-next-line
    }, [role, user]);

    useEffect(() => {
        if(!!accessToken === false || new Date(accessToken?.expiration) < new Date()) {
            refreshToken()
                .then((res:any) => {
                    dispatch(setTepngUser(res.userDetails))
                    dispatch(setAccessToken(res.accessToken))
                    dispatch(setRoles(res.roles))
                    dispatch(setPermissions(res.permissions))

                    const currentPagePath = window.location.pathname.split("/")[1]
                    const fRoles = res.roles.map((role: string) => role.toLowerCase().replaceAll(" ", ""))
                    
                    // Validate if user assigned roles is equal to the path tried to access
                    if(!fRoles.includes(currentPagePath)){
                        sessionStorage.setItem("token", res.accessToken.token)
                        window.location.replace(window.location.origin + "/unauthorized")                        
                    }

                    //Redirect user after login
                    const redirectURL = localStorage.getItem("redirectURL")
                    if(redirectURL !== null) window.location.replace(redirectURL)
                    localStorage.removeItem("redirectURL")
                })
                .catch((error:any) => console.log("error: ",error));
                
        } // eslint-disable-next-line
    }, [!!accessToken === false, new Date(accessToken?.expiration) < new Date()])
    return (
        <>
            {accessToken && <>
                <Header page={title} />
                <Sidebar page={title} />
                <div>{ children }</div>
            </>}
        </>
    )
}

export default Layout